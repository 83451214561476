<template>
  <div class="signature-canvas-container"
    :style="widthHP <= 768 ? 'width:370px;height:300px;' : 'width:700px;height:580px;'">
    <canvas class="signature-pad" id="signature-pad"
      :style="widthHP <= 768 ? 'width:370px;height:300px;' : 'width:680px;height:500px;'"></canvas>
  </div>
  <el-dialog v-model="dialogVisible" title="二次确认" width="500" :before-close="handleClose">
    <span>确认提交吗？</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleClick">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>
  <div style="margin-top: 20px;">
    <el-button type="primary" @click="clearSignature">清除签名</el-button>
    <el-button type="primary" @click="saveSignature">保存签名</el-button>
  </div>
  <!-- <button @click="saveSignature">保存签名</button> -->
</template>

<script>
import { ElMessage } from 'element-plus'
import SignaturePad from 'signature_pad';
import monitor from "@/utils/api/monitor.js"
export default {
  name: 'ElectronicSignature',
  data() {
    return {
      signaturePad: null,
      widthHP: '',
      dialogVisible: false,
      imgLink: ''
    };
  },
  props: ['approvalList'],
  mounted() {
    console.log(this.approvalList);
    this.widthHP = window.innerWidth
    var canvas = document.getElementById('signature-pad');
    function resizeCanvas() {
      canvas.width = window.innerWidth <= 768 ? 370 : 680
      canvas.height = window.innerWidth <= 768 ? 300 : 500
    }

    window.onresize = resizeCanvas;
    resizeCanvas();
    this.signaturePad = new SignaturePad(canvas, {
      penColor: 'black',
      backgroundColor: 'white',
      // velocityFilterWeight: 0.2,
      // maxWidth: 1.5,
      // minWidth: 0.2
    });
  },
  methods: {
    clearSignature() {
      this.signaturePad.clear();
    },
    saveSignature() {
      let that = this
      const fileData = this.signaturePad.toDataURL();
      const fileName = 'example.jpg'; // 设置文件名称
      // 调用函数并保存结果到变量中
      const blobObj = this.base64ToBlob(fileData);
      const formData = new FormData();
      formData.append('file', blobObj, fileName);
      monitor.appFileUpload(formData).then((res) => {
        console.log(res.data.link);
        this.imgLink = res.data.fileName
        if (res.success) {
          ElMessage({
            message: '签名上传成功',
            type: 'success',
          })
          that.dialogVisible = true
        } else {
          ElMessage({
            message: '签名上传失败',
            type: 'error',
          })
        }
      })
    },

    handleClick() {
      let that = this
      this.approvalList.forEach((el) => {
        const params = {
          status: '60',
          action: '60',
          picPath: this.imgLink,
          envAndSafe: "1",
          generalEvaluate: "1",
          resolveQuestion: '1',
          servicLevel: "5",
          serviceAttitude: "1",
          suggestions: "1",
          taskId: el.taskId
        }
        monitor.processBill(params, el.taskId).then((res) => {
          if (res.success) {
            ElMessage({
              message: el.billNumber + '提交成功',
              type: 'success',
            })

          } else {
            ElMessage({
              message: el.billNumber + '提交失败',
              type: 'error',
            })
          }
          that.dialogVisible = false
          that.$emit("type", true)
        })
      })
    },
    base64ToBlob(dataURL) {
      const arr = dataURL.split(','); // 分割Base64字符串
      const mime = arr[0].match(/:(.*?);/)[1]; // 获取MIME类型
      const bstr = atob(arr[1]); // Base64解码

      let n = bstr.length;
      const u8Arr = new Uint8Array(n);

      while (n--) {
        u8Arr[n] = bstr.charCodeAt(n);
      }

      return new Blob([u8Arr], { type: mime });
    }
  },
};
</script>

<style scoped>
.signature-canvas-container {
  /* width: 100%; */
  /* height: 600px; */
  position: relative;
  border: 1px dashed red;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
}
</style>